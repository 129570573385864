<template>
  <q-select
    v-model="localValue"
    use-input
    input-debounce="500"
    outlined
    dense
    option-label="label"
    :options="localOptions"
    @filter="filterFn"
    @input="onChange"
    :key="remountCounter"
    mapOptions
    :disable="disable"
    input-style="padding: 5px"
    :multiple="multiple"
    :debounce="500"
    :readonly="readonly"
    :clearable="clearable"
    class="r-select"
  >
    <template v-slot:no-option>
      <q-item>
        <q-item-section class="text-grey">
          No results
        </q-item-section>
      </q-item>
    </template>
    <template v-slot:append>
      <q-spinner-tail v-if="loading" color="primary" />
    </template>
  </q-select>
</template>
<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => {
        return [];
      }
    },
    value: {
      validator: prop => ["string", "object", "number"].includes(typeof prop),
      // type: [Number, String, Object],
      required: true,
      default: ""
    },
    url: {
      type: String,
      required: false
    },
    tableName: {
      type: String,
      required: false
    },
    emitObject: {
      type: Boolean,
      default: false
    },
    filter: {
      type: Object
    },
    disable: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      model: null,
      localOptions: [],
      term: "",
      remountCounter: 0,
      localValue: "",
      loading: false
    };
  },
  async created() {
    console.log(this.options)
    console.log(this.computedUrl)
    console.log(this.localOptions)
    if (!this.options.length) {
      await this.fetchOptions();
    } else {
      this.localOptions = this.options;
    }
    if (this.value) {
      this.localValue = this.value;
    } else {
      if (this.multiple) {
        this.localValue = [];
      } else {
        this.localValue = "";
      }
    }
  },

  methods: {
    async fetchOptions() {
      // TODO: implement paginatin.
      this.loading = true;
      let data = await this.$axios.$get(this.computedUrl, {
        term: this.term,
        ...this.filter
      });
      this.localOptions = data.data;
      this.loading = false;
    },

    async filterFn(val, update, abort) {
      this.term = val;
      await update(async () => {
        if (this.options.length) {
          this.localOptions = this.options.filter(
            option =>
              option.label &&
              option.label.toLowerCase().includes(val.toLowerCase())
          );
        } else {
          await this.fetchOptions();
        }
      });
    },
    emitValue(value, event = "input") {
      if (this.multiple) {
        let newVal = [];
        value.forEach(element => {
          if (this.emitObject) {
            newVal.push(element);
          } else if (typeof element == "object") {
            newVal.push(element.value);
          } else {
            newVal.push(element);
          }
        });
        this.$emit(event, newVal);
      } else {
        if (this.emitObject) {
          this.$emit(event, value);
        } else if (typeof value == "object" && value != null) {
          this.$emit(event, value.value);
        } else {
          this.$emit(event, value);
        }
      }
    },
    onChange(value) {
      this.emitValue(value);
    }
  },
  computed: {
    computedUrl() {
      if (this.tableName != "") {
        return `v1/dropdown/${this.tableName}`;
      }
      return this.url;
    }
  },
  watch: {
    localValue(newValue, oldValue) {
      this.remountCounter++;
      // this.emitValue(newValue);
      if (oldValue && newValue != oldValue) {
        this.emitValue(newValue, "change");
      }
    },
    value(newValue, oldValue) {
      this.localValue = newValue ? newValue : "";
    }
  }
};
</script>

<style lang="scss">
.q-field__control-container > .q-field__native.row {
  padding: 0 !important;
}
/* .q-field--borderless.q-field--dense .q-field__control {
  padding: 0 12px;
} */
/* .q-field__prefix,
.q-field__suffix,
.q-field__input {
  padding: 0 12px;
} */
// .q-field--borderless.q-field--dense .q-field__control {
//   .q-field__native {
//     padding: 0 12px;
//   }
//   .q-field__native.row {
//     padding: 0;
//   }
// }
// .q-validation-component {
//   padding: 0 12px;
// }
// .q-field--borderless .q-field__bottom,
// .q-field--standard .q-field__bottom,
// .q-field--borderless.q-field--dense .q-field__control,
// .q-field--standard.q-field--dense .q-field__control {
//   padding-left: 12px;
//   padding-right: 0;
// }
// .required-field {
//   .q-field__native {
//     padding: 0 12px;
//   }
// }
// .required-field {
//   .q-field--borderless.q-field--dense .q-field__control.row,
//   .q-field--standard.q-field--dense .q-field__control.row {
//     padding-left: 12px;
//   }
// }

// .q-field--borderless {
//   .q-field__control.row {
//     padding-left: inherit;
//   }
// }
.r-select {
  .q-field__control-container {
    padding-left: 12px;
  }
}
</style>
