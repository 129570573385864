import axios from "axios";
import Vue from "vue";
// import { createApp } from 'vue'
import { Notify } from "quasar";

const baseURL = process.env.API_URL;
const instance = axios.create({
  baseURL
});

const getConfig = () => {
  return {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    }
  };
};

let isRefreshing = false;
let pendingRequests = [];
let newTokens = {};

function addPendingRequests(cb) {
  pendingRequests.push(cb);
}

function onRefreshed() {
  pendingRequests.map(cb => cb());
}

function reFetch(originalRequest, from) {
  originalRequest.headers["Authorization"] = `Bearer ${newTokens.access_token}`;
  originalRequest.headers = originalRequest.headers || "get";
  if (from && from.toLowerCase() == "request") {
    return originalRequest;
  }
  return instance(originalRequest);
}

function checkRefreshStatus(timeout = 300, limit = 10, callback) {
  let i = 1;
  return new Promise(resolve => {
    const interval = setInterval(() => {
      if (!isRefreshing && i !== limit) {
        clearInterval(interval);
        if (callback && typeof callback == "function") {
          callback();
        }
        i++;
        resolve(true);
      } else if (i > limit) {
        resolve(false);
      }
    }, timeout);
  });
}

instance.interceptors.request.use(
  async config => {
    const originalRequest = config;
    if (!isRefreshing || config.url.includes("auth/refresh")) {
      return originalRequest;
    }
    let isRefreshed = await checkRefreshStatus();
    if (isRefreshed) {
      return reFetch(originalRequest, "request");
    }
    return originalRequest;
  },
  error => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => {
    if (!response) {
      throw response;
    }
    return response;
  },
  async error => {
    const { config, response } = error;
    if (!response) {
      return Promise.reject({
        error: {
          message:
            "Cannot process request at the moment. Please try again later."
        },
        ...error
      });
    }

    if (
      response.status === 401 &&
      response.data &&
      response.data.msg == "TOKEN_EXPIRED"
    ) {
      const originalRequest = config;
      if (!isRefreshing) {
        try {
          isRefreshing = true;
          const tokens = await Vue.prototype.authService.refreshToken();
          if (tokens) {
            newTokens = tokens;
          }
        } catch (err) {
          await Vue.prototype.authService.localLogout();
          // TODO: Handle Refresh Token Expired/Invalid Exceptions
          // if (err.error.message === "REFRESH_TOKEN_EXPIRED") {
          //   await Vue.prototype.authService.localLogout();
          // } else if (err.error.msg == "INVALID_REFRESH_TOKEN") {
          //   await Vue.prototype.authService.localLogout();
          // } else {
          // }
          Notify.create({
            type: "negative",
            message: "Session Expired!",
            caption: "Please re-login" || err.error.message
          });
          pendingRequests = [];
          return;
        } finally {
          isRefreshing = false;
        }
      }

      const requestPendingRequest = new Promise(resolve => {
        addPendingRequests(() => {
          resolve(reFetch(originalRequest));
        });
      });

      if (!isRefreshing && pendingRequests.length) {
        onRefreshed();
        pendingRequests = [];
      }
      return requestPendingRequest;
    }

    return Promise.reject(response.data);
  }
);
const axiosApi = {
  setBaseUrl: function(baseURL) {
    instance.defaults.baseURL = baseURL;
  },
  get: async function(target, payload) {
    const config = Object.assign(getConfig(), { params: payload });
    let result = await instance.get(target, config);
    return result.data;
  },
  // $get to maintain params payload.
  $get: async function(target, payload) {
    const config = Object.assign(getConfig(), { params: payload });
    let result = await instance.get(target, config);
    return result.data;
  },
  post: async function(target, payload, options = {}) {
    const config = getConfig();
    Object.assign(config.headers, options.headers);
    let result = await instance.post(target, payload, config);
    return result.data;
  },
  put: async function(target, payload, options = {}) {
    const config = getConfig();
    Object.assign(config.headers, options.headers);
    let result = await instance.put(target, payload, config);
    return result.data;
  },
  delete: async function(target) {
    return instance.delete(target, getConfig());
  },
  $delete: async function(target, payload) {
    const config = Object.assign(getConfig(), { params: payload });
    let result = await instance.delete(target, config);
    return result.data;
  },
  baseURL,
  instance
};
// const app = createApp({})
// app.config.globalProperties.$axios = instance // vue 3 equivalent to vue.prototype
Vue.prototype.$axios = axiosApi;

export default axiosApi;
