export default [
  {
    path: "region",
    component: () => import("pages/manage/region/List.vue"),
    meta: {
      name: "Region List",
      requiresAuth: true
    }
  },
  {
    path: "region/create",
    component: () => import("pages/manage/region/Create.vue"),
    meta: {
      name: "Create Region",
      requiresAuth: true,
      permission: "create_region"
    }
  },
  {
    path: "region/edit/:id",
    component: () => import("pages/manage/region/Edit.vue"),
    meta: {
      name: "Edit Region",
      requiresAuth: true,
      permission: "update_region"
    }
  }
];
