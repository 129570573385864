export default [
  {
    path: "eacs/",
    component: () => import("pages/obj2/eacs/List.vue"),
    meta: {
      name: "EACS List",
      requiresAuth: true
    }
  }

];
