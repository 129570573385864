export default [
    {
        path: "/dashboard",
        component: () => import("pages/dashboard/Index.vue"),
        meta: {
          name: "Dashboard Obj1",
          requiresAuth: true,
          permission: "menu_read_dashboard"
        }
  },
  {
        path: "/dashboard/obj2",
        component: () => import("pages/dashboard/IndexObj2.vue"),
        meta: {
          name: "Dashboard - Obj2",
          requiresAuth: true,
          permission: "menu_read_dashboard"
        }
    }
];
