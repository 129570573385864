export default [
  {
    path: "assessments/",
    component: () => import("pages/obj2/assessment/List.vue"),
    meta: {
      name: "Assessment List",
      requiresAuth: true
    }
  }

];
