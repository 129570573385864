<template>
  <div class="row">
    <q-markup-table
      flat
      bordered
      separator="horizontal"
      :class="`detail-table col-${span}`"
    >
      <tbody>
        <template v-for="(detail, key, index) in details">
          <tr
            v-if="
              (showAllColumns || visibleColumns.includes(key)) &&
                !hiddenColumns.includes(key)
            "
            :key="'detail_' + index"
          >
            <td>{{ getLabel(key) }}</td>
            <td class="text-weight-medium">{{ getFieldValue(key, detail) }}</td>
          </tr>
        </template>
      </tbody>
    </q-markup-table>
  </div>
</template>
<script>
export default {
  props: {
    labels: {
      type: Object,
      default: () => {
        return {};
      }
    },
    details: {
      type: Object,
      default: () => {
        return {};
      }
    },
    // options: all, first, initials
    uppercase: {
      type: String,
      default: "all"
    },
    showAllColumns: {
      type: Boolean,
      default: true
    },
    visibleColumns: {
      type: Array,
      default: () => []
    },
    span: {
      type: [Number, String],
      default: 8
    },
    hiddenColumns: {
      type: Array,
      default: () => []
    },
    mappings: {
      type: Object,
      default: () => ({})
    },
    booleans: {
      type: Array,
      default: () => []
    },
    booleanYes: {
      type: [String, Number],
      default: "Yes"
    },
    booleanNo: {
      type: [String, Number],
      default: "No"
    }
  },
  data() {
    return {};
  },
  methods: {
    formatColumnName(name, uppercase = "initials") {
      let arr = name.split("_");
      if (uppercase == "first") {
        let firstWord = arr[0];
        if (firstWord) {
          arr[0] =
            firstWord.charAt(0).toUpperCase() +
            firstWord.slice(1, firstWord.length);
        }
      } else {
        arr = arr.map(element => {
          if (uppercase == "all") {
            element = element.toUpperCase();
          } else if (uppercase == "initials") {
            element =
              element.charAt(0).toUpperCase() +
              element.slice(1, element.length);
          }
          return element;
        });
      }
      return arr.join(" ");
    },
    getFieldValue(key, detail, details = null) {
      if (typeof detail == "function") {
        return detail(details || this.details);
      }

      if (this.booleans.includes(key)) {
        if (
          ["1", 1, "YES", "yes", "Yes", "TRUE", "true", "True", true].includes(
            detail
          )
        ) {
          return this.booleanYes;
        } else if (
          ["2", 2, "NO", "no", "No", "FALSE", "false", "False", false].includes(
            detail
          )
        ) {
          return this.booleanNo;
        }
      }

      if (Object.keys(this.mappings).includes(key)) {
        return typeof this.mappings[key] == "object"
          ? this.mappings[key][detail]
          : detail;
      }
      return detail;
    },
    getLabel(key) {
      return this.labels[key]
        ? this.labels[key]
        : this.formatColumnName(key, this.uppercase);
    }
  }
};
</script>
<style>
.detail-table {
}
</style>
