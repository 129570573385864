<template>
  <div>
    <BlitzForm
      :id="id"
      :mode="mode"
      :class="{ 'left-labels': masterSchema.labelPosition == 'left' }"
      v-if="!loading"
      v-model="formData"
      v-bind="masterSchema"
      ref="form"
      :actionButtons="actionButtons"
      :actionButtonDefaults="actionButtonDefaults"
      :actionButtonsPosition="actionButtonsPosition"
      @cancel="cancelForm"
      @input="emitInput"
      :key="remountCounter"
      class="search-form"
    />
  </div>
</template>

<script>
import { BlitzForm } from "blitzar";

export default {
  components: { BlitzForm },
  props: {
    id: {
      type: [String, Number],
      default: "0"
    },
    editMode: {
      default: false,
      type: Boolean
    },
    post: {
      type: String
    },
    value: {
      type: Object
      // required:true
    },
    schema: {
      type: [Array, Object],
      default: function() {
        return {};
      }
    },
    autoclear: {
      type: Boolean,
      default: true
    },
    actionButtons: {
      type: Array,
      default: function() {
        return ["cancel", "save"];
      }
    },
    mode: {
      type: String,
      default: "edit"
    },
    notifyError: {
      type: Boolean,
      default: true
    },
    actionButtonsPosition: {
      type: String,
      default: "bottom"
    },
    editUrl: {
      type: String,
      required: false
    },
    fetchUrl: {
      type: String,
      default: ""
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    downloadOptions: {
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      actionButtonDefaults: {
        cancel: {
          component: "QBtn",
          color: "primary",
          outline: true,
          unelevated: true,
          showCondition: (_, { formData, mode }) => {
            return this.mode != "view";
          }
        },
        save: {
          component: "qBtn",
          color: "primary",
          unelevated: true,
          evaluatedProps: ["disable", "slot"],
          slot: () => {
            return this.editMode ? "UPDATE" : "SAVE";
          },
          disable: (val, { formData }) => {
            return this.processing;
          },
          showCondition: (_, { formData, mode }) => this.mode != "view",
          events: {
            click: (event, { formData }) => {
              this.saveFormData();
            }
          }
        },
        search: {
          component: "q-btn",
          color: "primary",
          unelevated: true,
          icon: "search",
          filled: false,
          round: true,
          size: "sm",
          // showCondition: (_, { formData }) => this.mode == "filter",
          // showCondition: (_, { formData }) => 1 == 1,
          events: {
            click: (event, { formData }) => {
              this.$emit("search", formData);
            }
          }
        },
        reset: {
          component: "q-btn",
          color: "primary",
          outline: true,
          round: true,
          size: "sm",
          icon: "restart_alt",
          unelevated: true,
          showCondition: (_, { formData }) => 1 == 1,
          events: {
            click: (event, { formData }) => {
              this.clearFormData();
              this.$nextTick(() => {
                this.$emit("reset");
              });
            }
          }
        },
        download: {
          component: "q-btn",
          color: "primary",
          outline: true,
          dense: true,
          size: "md",
          slot: "Download",
          icon: "download",
          unelevated: true,
          showCondition: (_, { formData }) => this.mode == "edit",
          events: {
            click: (event, { formData }) => {
              this.$refs.form
                .validate()
                .then(response => {
                  if (this.downloadOptions.download) {
                    this.handleDownload();
                  } else {
                    this.$emit("download", formData);
                  }
                })
                .catch(err => {});
            }
          }
        },
        upload: {
          component: "q-btn",
          color: "primary",
          outline: true,
          dense: true,
          size: "md",
          slot: "Upload",
          icon: "upload",
          unelevated: true,
          showCondition: (_, { formData }) => this.mode == "edit",
          events: {
            click: (event, { formData }) => {
              this.$refs.form
                .validate()
                .then(response => {
                    this.$emit("upload", formData);
                })
                .catch(err => { });
            }
          }
        }
      },
      loading: false,
      processing: false,
      masterSchema: {},
      formData: {},
      remountCounter: 0,
      refresh: false,
      editApi: ""
    };
  },
  methods: {
    saveFormData() {
      if (this.post != "") {
        this.$refs.form
          .validate()
          .then(response => {
            this.processing = true;
            this.$loader.setLoading();
            let keys = Object.keys(this.formData);
            new Promise((resolve, reject) => {
              keys.forEach((el, id) => {
                if (
                  !this.formData[el] &&
                  ![0, "0"].includes(this.formData[el])
                ) {
                  this.formData[el] = null;
                }
                if (id == keys.length) {
                  resolve();
                }
              });
            });
            if (this.editMode) {
              //if edit form mode
              this.$axios
                .put(this.editApi, this.formData)
                .then(response => {
                  this.$q.notify({
                    message: "Success",
                    caption:
                      this.masterSchema.editSuccessMessage ||
                      response.msg ||
                      "Edited Successfully",
                    type: "positive",
                    offset: 50
                  });
                  this.$emit("success", response.data);
                  this.processing = false;
                  this.$loader.setLoading(false);
                  if (this.masterSchema.redirectTo) {
                    this.$router.push(this.masterSchema.redirectTo);
                  } else if (this.masterSchema.redirectToOnEdit) {
                    this.$router.push(this.masterSchema.redirectToOnEdit);
                  }
                })
                .catch(error => {
                  this.$q.notify({
                    message: "Error",
                    caption:
                      this.masterSchema.errorMessage ||
                      error.msg ||
                      "Some Error Occured",
                    type: "negative",
                    offset: 50
                  });
                  this.processing = false;
                  this.$loader.setLoading(false);
                  // this.$emit('error');
                });
              // .finally(t => {
              //   this.processing = false;
              // });
            } else {
              //if create new form mode
              this.$axios
                .post(this.post, this.formData)
                .then(response => {
                  this.$q.notify({
                    message: "Success",
                    caption:
                      this.masterSchema.message ||
                      response.msg ||
                      "Operation Successfull",
                    type: "positive",
                    offset: 50
                  });
                  this.$emit("success", response.data);
                  this.clearFormData();
                  this.processing = false;
                  if (this.masterSchema.redirectTo) {
                    this.$router.push(this.masterSchema.redirectTo);
                  }
                })
                .catch(error => {
                  this.$emit("error", error);
                  if (this.notifyError) {
                    this.$q.notify({
                      message: "Error",
                      caption:
                        this.masterSchema.errorMessage ||
                        error.msg ||
                        "Some Error Occured",
                      type: "negative",
                      offset: 50
                    });
                  }

                  this.processing = false;
                  this.$loader.setLoading(false);
                  // this.$emit('error');
                })
                .finally(t => {
                  this.processing = false;
                  this.$loader.setLoading(false);
                });
            }
          })
          .catch(error => {});
      } else {
        this.$emit("save", this.formData);
      }
      return true;
    },
    clearFormData() {
      this.formData = {};
      this.remountCounter++;
    },
    refreshForm() {
      this.remountCounter = this.remountCounter + 1;
    },
    emitInput() {
      this.$emit("input", this.formData);
    },
    async validateForm() {
      await this.$refs.form.validate();
    },
    cancelForm() {
      this.$emit("cancel");
      this.clearFormData();
      if (this.schema.redirectToOnCancel) {
        this.$router.push(this.schema.redirectToOnCancel);
      } else if (this.schema.redirectTo) {
        this.$router.push(this.schema.redirectTo);
      }
    },
    async fetchEditData(id) {
      try {
        this.loading = true;
        let fetchUrl = this.fetchUrl
          ? this.fetchUrl
          : `${this.masterSchema.api}/${id}`;
        this.$loader.setLoading();
        const res = await this.$axios.get(fetchUrl);
        if (res && res.data) {
          this.formData = res.data;
          this.remountCounter++;
          // for (let key in res.data) {
          //   this.$set(this.form, key, res.data[key]);
          // }
        }
      } catch (error) {
        this.$q.notify({
          message: "Error",
          caption: error.msg || "Some Error Occured",
          type: "negative",
          offset: 50
        });
      } finally {
        this.loading = false;
        this.$loader.setLoading(false);
      }
    },
    increaseCounter() {
      this.remountCounter++;
    },
    async handleDownload() {
      try {
        this.loading = true;
        let api = this.downloadOptions.api || this.post;
        let res = await this.$axios.post(api, this.formData);
        this.$emit("downloaded", res);
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        let fileName =
          this.downloadOptions.fileName ||
          `${this.formData.tableName} ${new Date().toLocaleString()} .${this
            .downloadOptions.extension || "csv"}`;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        this.$q.notify({
          color: "negative",
          message: e.msg || "Error Occured"
        });
      } finally {
        this.loading = false;
      }
    },
    resetFields(fields = "") {
      if (typeof fields == "string") {
        this.$set(this.formData, fields``, null);
      } else if (Array.isArray(fields)) {
        fields.forEach(field => {
          this.$set(this.formData, field, null);
        });
      }
    }
    // fetchSchemaOfForm() {
    //   this.loading = true;
    //   this.$axios.get(`/form-schema/${this.id}`).then((response) => {
    //     this.masterSchema = response.data;
    //     this.loading = false;
    //   });
    // },
  },
  created() {
    if (Object.keys(this.schema).length === 0) {
      // this.fetchSchemaOfForm();
    } else {
      this.masterSchema = this.schema;
    }
    // if (this.value) {
    //   this.formData = this.value;
    // }
    const params = this.$route.params;
    if (this.editMode && params && params.id) {
      this.editApi = this.editUrl ? this.editUrl : `${this.post}/${params.id}`;
      this.fetchEditData(params.id);
    }
  },
  watch: {
    editMode: function(newVal, oldVal) {
      if (this.editUrl) {
        this.editApi = this.editUrl;
      } else {
        this.editApi = this.post;
      }
    }
  }
};
</script>

<style lang="scss">
.blitz-field {
  grid-gap: 0px 16px;
}
.q-field--with-bottom {
  padding-bottom: 6px;
  padding-top: 3px;
}
.required-field .blitz-field__label:after {
  content: "*";
  color: red;
}
.filter-form {
  .q-field__control.no-wrap {
    flex-wrap: wrap;
  }
}
</style>
