<template>
  <div style="width: 100%">
    <q-input
      :filled="filled"
      :value="displayValue"
      dense
      :outlined="outlined"
      :disable="disable"
      :readonly="readonly"
      :placeholder="placeholder"
      @focus="openDatePopup"
      :rules="rules"
      class="r-date"
    >
      <template v-slot:append v-if="!(readonly || disable)">
        <q-icon name="event" class="cursor-pointer">
          <q-popup-proxy
            ref="datePopup"
            transition-show="scale"
            transition-hide="scale"
          >
            <q-date
              minimal
              v-model="date"
              :landscape="landscape"
              :mask="mask"
              @input="hideDatePopup"
            >
            </q-date>
          </q-popup-proxy>
        </q-icon>
      </template>
    </q-input>
  </div>
</template>

<script>
import { date } from "quasar";

export default {
  props: {
    value: {
      required: false
    },
    mask: {
      type: String,
      default: "DD/MM/YYYY"
    },
    color: {
      type: String,
      default: "primary"
    },
    outlined: {
      type: Boolean,
      default: true
    },
    landscape: {
      type: Boolean,
      default: false
    },
    disable: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    rules: {
      type: [Array, Object],
      required: false
    }
  },
  data() {
    return {
      displayValue: ""
    };
  },
  methods: {
    handleInput(input) {
      this.$emit("input", value);
    },
    initializeDate(val) {
      this.displayValue = val;
    },
    openDatePopup() {
      this.$refs.datePopup.show();
    },
    hideDatePopup() {
      this.$refs.datePopup.hide();
    }
  },
  computed: {
    date: {
      get: function() {
        return this.value;
      },
      set: function(newVal) {
        this.displayValue = newVal;
        this.$emit("input", newVal);
      }
    }
  },
  created() {
    this.initializeDate(this.value);
  }
};
</script>

<style lang="scss">
.r-date {
  .q-field__control-container {
    padding-left: 12px;
  }
}
</style>
