var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('q-table',{key:_vm.remountCounter,staticClass:"my-sticky-dynamic sticky-actions-column",attrs:{"columns":_vm.schemaColumns,"loading":_vm.loadingListData,"flat":"","bordered":"","pagination":_vm.pagination,"data":_vm.listData,"virtual-scroll":"","card-style":"height: 500px !important;","rows-per-page-options":_vm.rowsPerPageOptions},on:{"update:pagination":function($event){_vm.pagination=$event},"request":_vm.handleRequest},scopedSlots:_vm._u([(false)?{key:"top-row",fn:function(props){return _vm._l((props.cols),function(col,indx){return _c('td',{key:'topRow_' + indx,staticStyle:{"border-bottom":"1px solid #e2e2e2"}},[(col.name != 'actions')?_c(col.component || _vm.defaultFilterInputComponent,_vm._b({tag:"component",attrs:{"outlined":"","dense":""},model:{value:(_vm.filterOptions[col.field]),callback:function ($$v) {_vm.$set(_vm.filterOptions, col.field, $$v)},expression:"filterOptions[col.field]"}},'component',col.props,false)):_c('div',[_c('q-btn',{attrs:{"icon":"las la-filter","color":"primary","unelevated":"","dense":"","round":"","size":"sm"},on:{"click":_vm.filterList}}),_c('q-btn',{staticClass:"q-ml-sm",attrs:{"icon":"las la-times-circle","color":"negative","unelevated":"","dense":"","round":"","size":"sm"},on:{"click":_vm.resetFilter}})],1)],1)})}}:null,{key:"body-cell",fn:function(props){return [_c('q-td',{attrs:{"props":props}},[(props.col.isBadge)?_c('q-badge',_vm._b({},'q-badge',
            (typeof props.col.badgeProps == 'function'
              ? props.col.badgeProps(props)
              : props.col.badgeProps) || { label: props.value }
          ,false)):(props.col.isImage)?_c('q-img',{attrs:{"src":props.row.image,"height":props.col.imageHeight || '25px',"fit":"contain"}}):(props.col.isDate)?_c('span',[_vm._v("\n          "+_vm._s(_vm.formatDate(
              props.value,
              props.col.dateMask || "DD/MM/YYYY HH:mm:ss"
            ))+"\n        ")]):(props.col.isIcon)?_c('span',[_c('q-icon',_vm._b({},'q-icon',
              (typeof props.col.iconProps == 'function'
                ? props.col.iconProps(props)
                : props.col.iconProps) || {}
            ,false))],1):_c('span',[_vm._v(_vm._s(props.value))])],1)]}},{key:"body-cell-actions",fn:function({ row, col, colsMap }){return [_c('q-td',{class:`sticky-position actions-column`},[_c('div',{staticClass:"row",staticStyle:{"width":"max-content"}},[(colsMap.actions && !colsMap.actions.uneditable)?_c('q-btn',{staticClass:"edit-btn q-mr-md text-primary",attrs:{"dense":"","round":"","flat":"","color":"primary","icon":"o_edit","size":"sm"},on:{"click":function($event){return _vm.handleMenuItemClick('edit', row)}}}):_vm._e(),(_vm.showActionButton)?_c('q-btn-dropdown',{attrs:{"rounded":"","color":"primary","padding":"4px 3px","dropdown-icon":"o_more_vert","size":"sm","no-icon-animation":"","unelevated":""}},[_c('q-list',[_vm._l((col.actionOptions),function(item,index){return _c('div',{key:'cAction_' + index},[(
                    (typeof item.visible == 'boolean' && item.visible) ||
                      (typeof item.visible == 'function' && item.visible(row))
                  )?_c('q-item',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"clickable":""},on:{"click":function($event){$event.preventDefault();return _vm.handleCustomAction(item, row)}}},[_c('q-item-section',[_c('q-item-label',[_vm._v(_vm._s(item.label))])],1)],1):_vm._e()],1)}),_vm._l((_vm.actions),function(item,index){return _c('q-item',{directives:[{name:"close-popup",rawName:"v-close-popup"}],key:'menuItem_' + index,attrs:{"clickable":""},on:{"click":function($event){$event.preventDefault();return _vm.handleMenuItemClick(item.type, row)}}},[_c('q-item-section',[_c('q-item-label',[_vm._v(_vm._s(item.label))])],1)],1)})],2)],1):_vm._e()],1)])]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }