import { render, staticRenderFns } from "./RList.vue?vue&type=template&id=b0e6bd28"
import script from "./RList.vue?vue&type=script&lang=js"
export * from "./RList.vue?vue&type=script&lang=js"
import style0 from "./RList.vue?vue&type=style&index=0&id=b0e6bd28&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QTable from 'quasar/src/components/table/QTable.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QTable,QBtn,QTd,QBadge,QImg,QIcon,QBtnDropdown,QList,QItem,QItemSection,QItemLabel});qInstall(component, 'directives', {ClosePopup});
