export default [
  {
    path: "clinic",
    component: () => import("pages/manage/clinic/List.vue"),
    meta: {
      name: "Clinic List",
      requiresAuth: true
    }
  },
  {
    path: "clinic/create",
    component: () => import("pages/manage/clinic/Create.vue"),
    meta: {
      name: "Create Clinic",
      requiresAuth: true,
      permission: "create_clinic"
    }
  },
  // Use this sample if
  {
    path: "clinic/edit/:id",
    component: () => import("pages/manage/clinic/Edit.vue"),
    meta: {
      name: "Edit Clinic",
      requiresAuth: true,
      permission: "update_clinic"
    }
  }
];
