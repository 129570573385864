export default [
  {
    path: "high-risk-count",
    component: () => import("pages/count/HighRiskList.vue"),
    meta: {
      name: "High Risk Count",
      requiresAuth: true
    }
  },
  {
    path: "non-high-risk-count",
    component: () => import("pages/count/NonHighRiskList.vue"),
    meta: {
      name: "Non High Risk Count",
      requiresAuth: true
    }
  }
];
