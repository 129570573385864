export default [
  {
    path: "disbursement",
    component: () => import("pages/disbursement/List.vue"),
    meta: {
      name: "Disbursement List",
      requiresAuth: true
    }
  },
  {
    path: "disbursement/obj2",
    component: () => import("pages/disbursement/obj2/List.vue"),
    meta: {
      name: "Disbursement List - Obj2",
      requiresAuth: true
    }
  },
  {
    path: "disbursement/edit/:id",
    component: () => import("pages/disbursement/Edit.vue"),
    meta: {
      name: "Edit Disbursement",
      requiresAuth: true
    }
  }
];
