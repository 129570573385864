<template>
  <div class="q-px-lg">
    <div class="row">
      <div class="col-md-6">
        <q-timeline color="secondary">
          <q-timeline-entry v-for="(item, index) in data" :key="index">
            <div>
              <template>
                <div class="cursor-pointer" @click="getAppointmentDetail(item)">
                  {{ getFormattedDate(item) }}
                </div>
              </template>
            </div>
          </q-timeline-entry>
        </q-timeline>
      </div>
      <div class="col-md-6">
        <q-card flat class="bg-grey-1 height-100" v-if="clinics">
          <q-card-section v-if="loading">
            <q-skeleton
              v-for="i in 3"
              class="q-mb-sm"
              :key="i"
              height="50px"
              animation="wave"
            />
          </q-card-section>
          <q-card-section class="height-100" v-else>
            <div class="row q-col-gutter-md" v-if="clinics.length">
              <div
                class="col-md-12"
                v-for="(clinic, index) in clinics"
                :key="index"
              >
                <div class="clinic-card">
                  <q-item>
                    <q-item-section>
                      {{ clinic.name }}
                    </q-item-section>
                    <q-item-section side>
                      <span class="text-h6 text-primary">{{
                        clinic.count
                      }}</span>
                    </q-item-section>
                  </q-item>
                </div>
              </div>
            </div>
            <div
              class="text-center height-100 flex items-center justify-center text-h6 text-weight-regular"
              v-else
            >
              No Appointments scheduled for this day
            </div>
          </q-card-section>
        </q-card>
      </div>
    </div>
  </div>
</template>
<script>
import { date } from "quasar";

export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    objective: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      loading: false,
      expanded: false,
      clinics: null
    };
  },
  methods: {
    async getAppointmentDetail(date) {
      let formatteddate = this.getDate(date);
      this.clinics = [];
      this.loading = true;
      let url = '/web/v1/dashboard/appointments';
      if (this.objective == 2) {
        url = '/web/v1/obj2/dashboard/appointments';
      }
      try {
        let res = await this.$axios.$get(
          `${url}?date=${formatteddate}`
        );
        if (res) {
          this.clinics = res.data;
          this.loading = false;
        }
      } catch (e) {
        console.log(e);
      }
    },
    getFormattedDate(date) {
      var options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric"
      };

      let newDate = new Date(date);
      return newDate.toLocaleDateString("en-US", options);
    },
    getDate(currDate) {
      // const timeStamp = new Date("2/2/2022");
      const currTime = date.extractDate(currDate, "MM/DD/YYYY");
      return date.formatDate(currTime, "DD/MM/YYYY");
    }
  },
  computed: {}
};
</script>
<style lang="scss">
.clinic-card {
  border: 1px solid #e2e2e2;
  //   padding: 20px;
  background-color: aliceblue;
  border-radius: 10px;
}
.height-100 {
  height: 100%;
}
</style>
