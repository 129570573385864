export default [
  {
    path: "blood-collection",
    component: () => import("pages/bloodCollection/List.vue"),
    meta: {
      name: "Blood Collection List",
      requiresAuth: true
    }
  },
  {
    path: "blood-collection/obj2",
    component: () => import("pages/bloodCollection/obj2/List.vue"),
    meta: {
      name: "Blood Collection List - Obj2",
      requiresAuth: true
    }
  },
];
