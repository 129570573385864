export default [
  {
    path: "ward",
    component: () => import("pages/manage/ward/List.vue"),
    meta: {
      name: "Ward List",
      requiresAuth: true
    }
  },
  {
    path: "ward/create",
    component: () => import("pages/manage/ward/Create.vue"),
    meta: {
      name: "Create Ward",
      requiresAuth: true,
      permission: "create_ward"
    }
  },
  {
    path: "ward/edit/:id",
    component: () => import("pages/manage/ward/Edit.vue"),
    meta: {
      name: "Edit Ward",
      requiresAuth: true,
      permission: "update_ward"
    }
  }
];
