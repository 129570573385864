export default [
  {
    path: "data-collection",
    component: () => import("pages/dataCollection/List.vue"),
    meta: {
      name: "Data Collection List",
      requiresAuth: true
    }
  },
    {
    path: "data-collection/obj2",
    component: () => import("pages/obj2/datacollection/List.vue"),
    meta: {
      name: "Obj2 Data Collection List",
      requiresAuth: true
    }
  },

];
