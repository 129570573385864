export default [
  {
    path: "followup",
    component: () => import("pages/followup/List.vue"),
    meta: {
      name: "Followup List",
      requiresAuth: true
    }
  },
  {
    path: "followup/edit/:id",
    component: () => import("pages/followup/Edit.vue"),
    meta: {
      name: "Followup Edit",
      requiresAuth: true
    }
  },
    {
    path: "followup/obj2",
    component: () => import("pages/obj2/registration/List.vue"),
    meta: {
      name: "Registration List",
      requiresAuth: true
    }
  },
  {
    path: "followup/obj2/edit/:id",
    component: () => import("pages/obj2/registration/Edit.vue"),
    meta: {
      name: "Registration Edit",
      requiresAuth: true
    }
  }
];
