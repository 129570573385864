export default [
  {
    path: "district",
    component: () => import("pages/manage/district/List.vue"),
    meta: {
      name: "District List",
      requiresAuth: true
    }
  },
  {
    path: "district/create",
    component: () => import("pages/manage/district/Create.vue"),
    meta: {
      name: "Create District",
      requiresAuth: true,
      permission: "create_district"
    }
  },
  {
    path: "district/edit/:id",
    component: () => import("pages/manage/district/Edit.vue"),
    meta: {
      name: "Edit District",
      requiresAuth: true,
      permission: "update_district"
    }
  }
];
