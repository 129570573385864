export default [
  {
    path: "enrollment",
    component: () => import("pages/enrollment/List.vue"),
    meta: {
      name: "Enrollment List",
      requiresAuth: true
    }
  },
  {
    path: "enrollment/create",
    component: () => import("pages/enrollment/Create.vue"),
    meta: {
      name: "Enrollment Form",
      requiresAuth: true
    }
  },
  {
    path: "enrollment/edit/:id",
    component: () => import("pages/enrollment/Edit.vue"),
    meta: {
      name: "Enrollment Form",
      requiresAuth: true
    }
  },
  {
    path: "enrollment/view/:id",
    component: () => import("pages/enrollment/View.vue"),
    meta: {
      name: "Enrollment View",
      requiresAuth: true
    }
  },
  { 
    path: "enrollment/obj2",
    component: () => import("pages/enrollment/obj2/List.vue"),
    meta: {
      name: "Enrollment List",
      requiresAuth: true
    }
  },
  {
    path: "enrollment/obj2/create",
    component: () => import("pages/enrollment/obj2/Create.vue"),
    meta: {
      name: "Enrollment Form",
      requiresAuth: true
    }
  },
  {
    path: "enrollment/obj2/edit/:id",
    component: () => import("pages/enrollment/obj2/Edit.vue"),
    meta: {
      name: "Enrollment Form",
      requiresAuth: true
    }
  },
  {
    path: "enrollment/obj2/view/:id",
    component: () => import("pages/enrollment/obj2/View.vue"),
    meta: {
      name: "Enrollment View",
      requiresAuth: true
    }
  }
];
