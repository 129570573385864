export default [
  {
    path: "download",
    component: () => import("pages/download/index.vue"),
    meta: {
      name: "Download",
      requiresAuth: true,
    },
  },
  {
    path: "uploads/original",
    component: () => import("pages/upload/originalList.vue"),
    meta: {
      name: "Upload",
      requiresAuth: true,
    },
  },
  {
    path: "uploads/original/create",
    component: () => import("pages/upload/originalCreate.vue"),
    meta: {
      name: "Upload",
      requiresAuth: true,
    },
  },
  {
    path: "uploads",
    component: () => import("pages/upload/list.vue"),
    meta: {
      name: "Upload",
      requiresAuth: true,
    },
  },
  {
    path: "uploads/create",
    component: () => import("pages/upload/create.vue"),
    meta: {
      name: "Upload",
      requiresAuth: true,
    },
  },
];
